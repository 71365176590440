//
// variables.scss
//

// fonts
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

//color
$primary:   #323232;
$success:   #34af7a;
$info:      #49c6e5;
$warning:   #e9aa39;
$danger:    #f01414;
$white:     #ffffff;
$dark:      #13121d;
$light:     #f8f9fa;
$muted:     #272d32;
$black:     #000000;
$purple:    #9894ff;
$cyan:      #00e6e6;
$blue:      #3083DC;
$pink:      #f13e74;

// stylelint-disable
$gray-100:  #f8f9fa;
$gray-200:  #e9ecef;
$gray-300:  #f7f7f7;
$gray-400:  #cacaca;
$gray-500:  #adb5bd;
$gray-600:  #6c757d;
$gray-700:  #444444;
$gray-800:  #343a40;
$gray-900:  #1f1f1f;

// stylelint-disable
$gray-dark-100:  #505050;
$gray-dark-200:  #343a40;
$gray-dark-300:  #495057;
$gray-dark-400:  #6c757d;
$gray-dark-500:  #888888;
$gray-dark-600:  #ced4da;
$gray-dark-700:  #dee2e6;
$gray-dark-800:  #e9ecef;
$gray-dark-900:  #f8f9fa;

$colors: (
  "primary":  $primary,
  "success":  $success,
  "info":     $info,
  "warning":  $warning,
  "danger":   $danger,
  "dark":     $dark,
  "cyan":     $cyan,
  "blue":     $blue,
  "muted":    $muted,
  "purple":   $purple,
  "pink":     $pink,
  "light":    $light,
  "white":    $white,
);

$theme-colors: (
  "primary":  $primary,
  "success":  $success,
  "info":     $info,
  "warning":  $warning,
  "danger":   $danger,
  "light":    $light,
  "dark":     $dark,
  "muted":    $muted,
  "white":    $white,
);

//box-shadow
$box-shadow:              rgba($black, 0.05) 0px 8px 24px;

$font-weight-medium: 500;
$font-weight-semibold: 600;

// font-family
$font-family-base:        'Montserrat', sans-serif;
