// 
//  footer
// 

// agency-footer

.agency-footer {
    background-color: $gray-900;
    .footer-heading {
        letter-spacing: 4px;
        position: relative;
        color: rgba($light, 0.8);
    }
    .footer-link {
        li {
            a {
                color: rgba($light, 0.7);
                line-height: 38px;
                font-size: 14px;
                transition: all 0.5s;
                &:hover {
                    color: $white;
                }
            }
        }
    }
    .subscribe-desc {
        color: rgba($light, 0.7);
    }
    .agency-footer-subcribe {
        form {
            position: relative;
            max-width: 400px;
            input {
                padding: 12px 20px;
                width: 100%;
                font-size: 15px;
                color: $white;
                border: none;
                outline: none !important;
                padding-right: 75px;
                padding-left: 15px;
                background-color: $primary;
                border-radius: 5px;
            }
            button {
                position: absolute;
                top: 0px;
                right: 0px;
                outline: none !important;
                border-radius: 0px 5px 5px 0px;
                font-size: 14px;
                padding: 11px 20px;
            }
        }
    }
    .copy-right {
        color: rgba($light, 0.7);
    }
}

@media (max-width: 768px) {
    .footer-heading {
        margin-top: 30px;
    }
}

/*------ blog-bg-footer ------*/

.blog-bg-footer {
    background-color: $gray-900;
    .footer-heading {
        letter-spacing: 4px;
        position: relative;
        color: $gray-200;
    }
    .footer-link {
        a {
            color: rgba($light, 0.7);
            line-height: 38px;
            font-size: 14px;
            transition: all 0.5s;
            &:hover {
                color: $white;
            }
        }
    }
    .left-recent-content {
        padding-bottom: 5px;
        .left-recent-img  {
            img {
                max-width: 80px;
                height: 46px;
                border-radius: 3px;
            }
        }
        .left-recent-title {
            color: rgba($light, 0.7) !important;
        }
    }
    .copy-right {
        color: rgba($light, 0.7);
    }
}

/*------ BOOK FOOTER ------*/

.book-bg-footer {
    background-color: $gray-900;
    .book-footer-box {
        .book-footer-icon i {
            font-size: 50px;
        }
        .book-footer-social {
            a {
                color: $gray-400;
                font-size: 18px;
                transition: all 0.5s;
                width: 39px;
                height: 39px;
                background-color: $gray-700;
                display: inherit;
                line-height: 39px;
                &:hover {
                    color: $white;
                    background-color: $warning;
                }
            }
        }
        .copy-right {
            color: rgba($light, 0.7);
        }
    }
}

/*------ GYM-FOOTER ------*/

.gym-bg-footer {
    background: rgba($black, 0.9);
    .gym-footer-terms {
        li {
            &::after {
                content: "|";
                margin: 0 20px;
                color: rgba($light, 0.7);
            }
            a {
                color: rgba($light, 0.7) !important;
                transition: all 0.5s;
                &:hover {
                    color: $white !important;
                    transition: all 0.5s;
                }
            }
        }
    }
    .gym-footer-alt {
        p {
            letter-spacing: 2px;
        }
        .copy-right {
            color: rgba($light, 0.7);
        }
    }
}

/*------ PHOTOGRAPHER FOOTER ------*/

.photographer-instagram {
    position: relative;
    left: -70px;
}

@media (max-width: 768px) {
    .photographer-instagram {
        left: 0px;
    }
}

/*------ SOFTWERE-FOOTER ------*/

.bg-softwere-footer {
    background-color: $black;
}

.softwere-footer-icon i {
    font-size: 30px;
}
